@import '../../../../styles/var';
$card-width: 228px;

.mpk-company-card{
  width: $card-width;
  display: inline-table;
  .company-card-logo{
    width: $card-width;
    height: $card-width;
    border-radius: 0;
    background: #836464;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .md-icon{
      font-size: 92px;
      color: $mpk-action-footer-color;
    }
  }
  .company-card-info{
    border-top: thin solid $mpk-action-footer-color;
    .md-icon{
      color: #bbb;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-company-card{
    width: 100%;
    margin: 0 0 16px 0 !important;
    .company-card-logo{
      width: 100%;
    }
  }
}